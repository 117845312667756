<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-form-item label="院校:">
          <el-input v-model="searchForm.collegename" clearable />
        </el-form-item>
        <el-form-item label="往来类型:">
          <el-select v-model="searchForm.type" placeholder="请选择" clearable>
            <el-option
              v-for="(item, i) in contactTypes"
              :key="i"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收付款状态:">
          <el-select v-model="searchForm.status" placeholder="请选择" clearable>
            <el-option
              v-for="(item, i) in paymentStates"
              :key="i"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
      <div class="r-w">

      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
			border
			:header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        label="流水号"
        prop="id"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="是否已确认"
        prop="isok"
        align="center"
        width="100"
      >
        <template slot-scope="scope">
          {{ available[scope.row.isok] }}
        </template>
      </el-table-column>
      <el-table-column
        label="交易类型"
        prop="jiaoyitype"
        align="center"
        width="100"
      >
        <template slot-scope="scope">
          {{ bankorcash[scope.row.jiaoyitype] }}
        </template>
      </el-table-column>
      <el-table-column
        label="收付款状态"
        prop="status"
        align="center"
        width="100"
      >
        <template slot-scope="scope">
          {{ paymentState[scope.row.status] }}
        </template>
      </el-table-column>
      <el-table-column label="年级" prop="grade" align="center" width="100">
      </el-table-column>
      <el-table-column
        label="院校"
        prop="collegename"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column label="学年" prop="iyear" align="center" width="100">
      </el-table-column>
      <el-table-column label="类别" prop="keleival" align="center" width="100">
      </el-table-column>
      <el-table-column
        label="项目类型"
        prop="projecttype"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column label="应收总额" prop="total" align="center" width="100">
      </el-table-column>
      <el-table-column
        label="实收总额"
        prop="total2"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        label="应付总额"
        prop="total3"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        label="实付总额"
        prop="total4"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column label="往来类型" prop="type" align="center" width="130">
      </el-table-column>
      <el-table-column
        label="对方单位名称"
        prop="unitname"
        align="center"
        width="130"
      >
      </el-table-column>
      <el-table-column
        label="所属教学点名称"
        prop="schoolorgname"
        align="center"
        width="130"
      >
      </el-table-column>
      <el-table-column
        label="所属分公司名称"
        prop="companyorgname"
        align="center"
        width="130"
      >
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import { summary_list } from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import {
  _paymentState,
  _paymentStates,
  _available,
  _bankorcash,
} from "@/assets/js/filedValueFlag";

export default {
  mixins: [part],
  name: "summary",
  components: {},
  props: {},
  inject: ["reload"],
  data() {
    //数值表单规则
    var checkfield = (rule, value, callback) => {
      if (!(value + "")) {
        return callback(new Error("必填字段"));
      } else {
        if (isNaN(Number(value))) {
          callback(new Error("请输入数字值"));
        } else {
          callback();
        }
      }
    };
    return {
      searchForm: {},
      itemId: null, //首表格项id
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, //显示表单框
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单

      contactTypes: [
        "院校往来应收",
        "院校往来应付",
        "日常往来应收",
        "日常往来应付",
      ],

      paymentState: _paymentState,
      paymentStates: _paymentStates,
      available: _available,
      bankorcash: _bankorcash,
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    ...mapActions("global", ["onExtendClick"]),
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: summary_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },

    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },

    //刷新 click
    btnReload() {
      this.getDataList();
    },

    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>


<style  lang="scss">
</style>